import { CREATE_CATEGORY, GET_CATEGORY, GET_DETAIL_CATEGORY } from "../actionTypes"

export const getCategory = (payload: any) => {
  return {
    type: GET_CATEGORY,
    payload,
  }
}

export const getDetailCategory = (payload: any) => {
  return {
    type: GET_DETAIL_CATEGORY,
    payload,
  }
}

export const createCategory = (payload: any) => {
  return {
    type: CREATE_CATEGORY,
    payload,
  }
}
