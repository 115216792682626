import { Button, Drawer, Space } from "antd"
import React from "react"

// const { Option } = Select

const PublishDrawer: React.FC<{ open: boolean; setOpen: any; publish: any }> = props => {
  const onClose = () => {
    props.setOpen(false)
  }

  return (
    <Drawer
      title="Are you ready to publish?"
      width={720}
      onClose={onClose}
      open={props.open}
      bodyStyle={{ paddingBottom: 80 }}
      extra={
        <Space>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={() => {
              console.log("hihi")
              props.publish()
            }}
            type="primary"
          >
            Publish
          </Button>
        </Space>
      }
    >
      ...
    </Drawer>
  )
}

export default PublishDrawer
