import { ACCESS_TOKEN } from "@/constants"
import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache, ServerParseError } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"
// import { TypedTypePolicies } from "./__generated__/apollo"

const headersLink = setContext(async (req, ctx) => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN) ?? "null"

  return {
    headers: {
      ...ctx.headers,
      authorization: accessToken ? `Bearer ${accessToken}` : "",
    },
  }
})

const errorLink = onError(({ graphQLErrors, networkError, operation, forward, ...rest }) => {
  console.log({ graphQLErrors, networkError })
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      switch (err?.extensions?.code) {
        // Apollo Server sets code to UNAUTHENTICATED
        // when an AuthenticationError is thrown in a resolver
        case "UNAUTHENTICATED":
          // Modify the operation context with a new token

          // Retry the request, returning the new observable
          return forward(operation)
      }
    }
  }

  // To retry on network errors, we recommend the RetryLink
  // instead of the onError link. This just logs the error.
  const error = networkError as ServerParseError
  if (error && error.statusCode === 401) {
    window.location.href = `${window.location.origin}/logout`
    return
  }
})

export function createApolloClient() {
  return new ApolloClient({
    link: ApolloLink.from([
      errorLink,
      headersLink,
      createHttpLink({
        // uri: 'http://localhost:9000/graphql' as string,
        uri: process.env.NODE_ENV === "production" ? "https://...api.../graphql" : "https://...api.../graphql",
      }),
    ]),
    cache,
    connectToDevTools: process.env.NODE_ENV !== "production",
    // connectToDevTools: true,
  })
}
// interface TypedTypePolicies {
//   BankInfo: {
//     merge: boolean
//   }
// }

const typePolicies: any = {
  BankInfo: {
    merge: true,
  },
}

export const cache: InMemoryCache = new InMemoryCache({ typePolicies })
