import { AUTH_FORGOT_PASSWORD, AUTH_RESET_PASSWORD, CHECK_AUTHENTICATION, LOGIN, LOGOUT } from "../actionTypes"

export const handleLogin = (payload: any) => {
  return {
    type: LOGIN,
    payload,
  }
}

export const handleLogout = () => {
  return {
    type: LOGOUT,
  }
}

export const handleCheckAuthentication: any = () => {
  return {
    type: CHECK_AUTHENTICATION,
  }
}

export const forgotPassword = (payload: any) => {
  return {
    type: AUTH_FORGOT_PASSWORD,
    payload,
  }
}

export const resetPassword = (payload: any) => {
  return {
    type: AUTH_RESET_PASSWORD,
    payload,
  }
}
