import { useEffect, useState } from "react"
import ReactQuill from "react-quill"

interface EditorPros {
  onCreate: boolean
  html: any
  defaultHtml?: string
}

export default function Editor(props: EditorPros) {
  const [editorHtml, setEditorHtml] = useState<string | undefined>("")

  useEffect(() => {
    props.html(editorHtml)
    // eslint-disable-next-line
  }, [props.onCreate])

  useEffect(() => {
    setEditorHtml(props.defaultHtml)
  }, [props.defaultHtml])

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
      ["link", "image"],
      ["clean"],
    ],
  }

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ]

  const handleChange = (html: string) => {
    setEditorHtml(html)
  }
  console.log("default: ", props.defaultHtml)
  console.log("ed: ", editorHtml, typeof editorHtml)
  return (
    <ReactQuill
      value={editorHtml}
      onChange={handleChange}
      theme="snow"
      placeholder="Start posting something!"
      modules={modules}
      formats={formats}
    ></ReactQuill>
  )
}
