import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

const REQUIRE_MESSAGE = "Không được để trống"

export const validation = {
  authentication: {
    login: () => {
      return yupResolver(
        yup.object({
          username: yup.string().required(REQUIRE_MESSAGE).min(5, "Tên đăng nhập tối thiểu 5 ký tự"),
          password: yup.string().required(REQUIRE_MESSAGE).min(6, "Mật khẩu tối thiểu 6 kí tự"),
        }),
      )
    },
  },
}
