import { getDetailPost } from "@/modules/core/pages/post/actions"
import Editor from "@/modules/core/pages/post/component/Editer"
import PublishDrawer from "@/modules/core/pages/post/component/PublishDrawer"
import { dispatch } from "@/redux"
import { PageContainer } from "@ant-design/pro-components"
import { Button } from "antd"
import React, { useEffect, useState } from "react"
import "react-quill/dist/quill.snow.css"
import { shallowEqual, useSelector } from "react-redux"
import { useParams } from "react-router-dom"

export const Edit: React.FC = () => {
  const { id } = useParams()

  useEffect(() => {
    if (detail) {
      dispatch(getDetailPost({ id }))
    }
  }, [])

  const [value, setValue] = useState<string>("")
  const [onCreate, setOnCreate] = useState<boolean>(false)
  const [openDrawer, setOpenDrawer] = useState<boolean>(false)
  const detail = useSelector((state: any) => state?.post?.detail || {}, shallowEqual)

  console.log("detail: ", detail)
  const onPublish = () => {
    // dispatch(getDetailPost({ id }))
    //update
  }

  return (
    <PageContainer
      header={{
        title: "Post Create",
        ghost: true,
        extra: [],
      }}
      footer={[
        <Button type="link">Save draft</Button>,
        <Button type="dashed">Preview</Button>,
        <Button
          type="primary"
          onClick={() => {
            setOnCreate(!onCreate)
            setOpenDrawer(true)
          }}
        >
          Publish
        </Button>,
      ]}
    >
      <Editor onCreate={onCreate} html={setValue} defaultHtml={detail?.content} />
      <PublishDrawer open={openDrawer} setOpen={setOpenDrawer} publish={onPublish} />
    </PageContainer>
  )
}
