export const GET_POST = "post.get"
export const GET_POST_SUCCESS = "post.get_success"
export const GET_POST_FAILED = "post.get_failed"

export const GET_DETAIL_POST = "post.get_detail"
export const GET_DETAIL_POST_SUCCESS = "post.get_detail_success"
export const GET_DETAIL_POST_FAILED = "post.get_detail_failed"

export const CREATE_POST = "post.create"
export const CREATE_POST_SUCCESS = "post.create_success"
export const CREATE_POST_FAILED = "post.create_failed"
