import { CREATE_POST_SUCCESS, GET_DETAIL_POST_SUCCESS, GET_POST_SUCCESS } from "../actionTypes"
import { IPostState } from "../types"

interface ActionProps {
  type: string
  payload: any
}

const initState: IPostState = {
  list: [],
  detail: {},
}

export const post = (state: IPostState = initState, action: ActionProps) => {
  const { type, payload } = action

  switch (type) {
    case GET_POST_SUCCESS: {
      return {
        ...state,
        list: payload,
      }
    }

    case CREATE_POST_SUCCESS: {
      return {
        ...state,
        list: {
          ...state.list.concat(action.payload),
        },
      }
    }

    case GET_DETAIL_POST_SUCCESS: {
      return {
        ...state,
        detail: action.payload,
      }
    }

    default: {
      return state
    }
  }
}
