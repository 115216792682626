import { dispatch } from "@/redux"
import { PageContainer } from "@ant-design/pro-layout"
import { Button, Form, Input, Select } from "antd"
import { valueType } from "antd/es/statistic/utils"
import React, { useEffect } from "react"
import { shallowEqual, useSelector } from "react-redux"
import { createCategory, getCategory } from "./actions"

export const Create: React.FC = () => {
  useEffect(() => {
    dispatch(getCategory({ page: 1, limit: 1000 }))
  }, [])
  const categories = useSelector((state: any) => state?.category?.list || [], shallowEqual)

  const [form] = Form.useForm()

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
  }

  const handleSelectParent = (e: valueType) => {
    console.log("e: ", e)
  }

  const onFinish = (value: any) => {
    console.log("value: ", value)
    dispatch(createCategory(value))
  }

  return (
    <PageContainer
      header={{
        title: "Create Category",
        ghost: true,
        extra: [],
      }}
      footer={[
        <Button form="create-category" htmlType="submit" type="primary">
          Add new category
        </Button>,
      ]}
    >
      <Form
        initialValues={{ name: "", slug: "", parent_category: "" }}
        id="create-category"
        {...layout}
        form={form}
        onFinish={onFinish}
      >
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="slug" label="Slug">
          <Input />
        </Form.Item>
        <Form.Item name="parent_category" label="Parent Category">
          <Select placeholder="Select a option and change input text above" onChange={handleSelectParent} allowClear>
            {categories.map((i: any) => (
              <Select.Option value={i?.key}>{i?.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </PageContainer>
  )
}
