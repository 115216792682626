import { createApolloClient } from "@/apollo-client"
import { AuthProvider } from "@/components/auth"
import { ApolloProvider } from "@apollo/client"
import { ConfigProvider } from "antd"
import enUS from "antd/lib/locale/en_US"
import { Provider as ReduxProvider } from "react-redux"
import { Navigator } from "./Navigator"
import store from "./redux/store"

const App = () => {
  const apolloClient = createApolloClient()
  return (
    <ConfigProvider locale={enUS}>
      <ApolloProvider client={apolloClient}>
        <ReduxProvider store={store}>
          <AuthProvider>
            <Navigator />
          </AuthProvider>
        </ReduxProvider>
      </ApolloProvider>
    </ConfigProvider>
  )
}

export default App
