import { Paths } from "@/constants"
import history from "@/navigation"
import {
  CrownFilled,
  LogoutOutlined,
  ProfileFilled,
  QuestionCircleFilled,
  SmileFilled,
  YoutubeOutlined,
} from "@ant-design/icons"
import { MenuDataItem, ProLayout, ProSettings, SettingDrawer } from "@ant-design/pro-components"
import { Dropdown, Layout } from "antd"
import React, { FC, useEffect, useState } from "react"

export const ProLayoutCustom: FC<{ children: React.ReactNode }> = props => {
  const [settings, setSetting] = useState<Partial<ProSettings> | undefined>({
    fixSiderbar: true,
    layout: "mix",
  })
  const profile = {
    name: "Lam Boyer",
    role: "Admin",
    avt: "https://img.freepik.com/premium-vector/cute-pig-is-sitting-with-two-finger-cartoon-illustration_403370-527.jpg?w=2000",
  }

  const [pathname, setPathname] = useState<any>("")

  useEffect(() => {
    if (pathname) {
      history.push(pathname)
    }
  }, [pathname])

  const proLayoutProps: object = {
    avatarProps: {
      src: profile.avt,
      render: (_props: string, dom: React.ReactNode) => {
        return (
          <Dropdown
            menu={{
              items: [
                {
                  key: "profile",
                  icon: <ProfileFilled />,
                  label: "Profile",
                  onClick: () => console.log("profile"),
                },
                {
                  key: "logout",
                  icon: <LogoutOutlined />,
                  label: "Đăng xuất",
                  onClick: () => console.log("logout"),
                },
              ],
            }}
          >
            {dom}
          </Dropdown>
        )
      },
    },
    actionsRender: () => {
      return [<QuestionCircleFilled key="QuestionCircleFilled" />]
    },
    menuFooterRender: (props: { collapsed: boolean }) => {
      if (props?.collapsed) return undefined
      return (
        <div
          style={{
            textAlign: "center",
            paddingBlockStart: 12,
          }}
        >
          <div>© 2023 Made with love</div>
          <div>by Tamda CMS</div>
        </div>
      )
    },
    onMenuHeaderClick: (e: React.MouseEvent<HTMLDivElement>) => console.log(e),

    prefixCls: "my-prefix",
    route: {
      path: "/",
      routes: [
        {
          path: "/",
          name: "Dashboard",
          icon: <SmileFilled />,
        },
        {
          path: "1",
          name: "Posts",
          icon: <CrownFilled />,
          routes: [
            {
              path: Paths.PRIVATE.POST.PATH,
              name: "List",
            },
            {
              path: Paths.PRIVATE.CATEGORY.PATH,
              name: "Category",
            },
          ],
        },
        {
          path: "/tag",
          name: "Tag",
          icon: <YoutubeOutlined />,
        },
      ],
    },
    location: { pathname },
    menuItemRender: (item: MenuDataItem, dom: React.ReactNode, key: number) => (
      <div
        key={key}
        onClick={() => {
          setPathname(item.path)
        }}
      >
        {dom}
      </div>
    ),
  }

  return (
    <Layout className="layout" id="">
      <ProLayout {...settings} {...proLayoutProps}>
        {props.children}

        <SettingDrawer
          pathname={pathname}
          settings={settings}
          onSettingChange={changeSetting => {
            setSetting(changeSetting)
          }}
          disableUrlParams={false}
        />
      </ProLayout>
    </Layout>
  )
}
