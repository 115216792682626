export const AUTH_UPDATE_TOKEN = "auth.update_token"
export const AUTH_UPDATE_PROFILE = "auth.update_profile"
export const LOGIN = "auth.login"
export const LOGIN_SUCCESS = "auth.login_success"
export const LOGIN_FAILED = "auth.login_failed"
export const CHECK_AUTHENTICATION = "auth.check_authentication"
export const LOGOUT = "auth.logout"
export const AUTH_SET_LOADING = "auth.set_loading"
export const AUTH_FORGOT_PASSWORD = "auth.forgot_password"
export const AUTH_FORGOT_PASSWORD_UPDATE = "auth.forgot_password_update"
export const AUTH_RESET_PASSWORD = "auth.reset_password"
export const SESSION_EXPIRED = "auth.session_expired"
export const SESSION_EXPIRED_SUCCESS = "auth.session_expired_success"
