import * as ActionTypes from "../actionTypes"
import { ICategoryState } from "../types"

interface ActionProps {
  type: string
  payload: any
}

const initState: ICategoryState = {
  list: [],
  detail: {},
}

export const category = (state: ICategoryState = initState, action: ActionProps) => {
  const { type, payload } = action

  switch (type) {
    case ActionTypes.GET_CATEGORY_SUCCESS: {
      return {
        ...state,
        list: payload,
      }
    }

    case ActionTypes.CREATE_CATEGORY_SUCCESS: {
      return {
        ...state,
      }
    }

    case ActionTypes.GET_DETAIL_CATEGORY_SUCCESS: {
      return {
        ...state,
        detail: action.payload,
      }
    }

    default: {
      return state
    }
  }
}
