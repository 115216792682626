import { dispatch } from "@/redux"
import { PageContainer } from "@ant-design/pro-layout"
import { Button, Form, Input, Select } from "antd"
import React, { useEffect } from "react"
import { shallowEqual, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { createCategory, getCategory, getDetailCategory } from "./actions"

export const Edit: React.FC = () => {
  const { id } = useParams()
  const categories = useSelector((state: any) => state?.category?.list || [], shallowEqual)
  const detail = useSelector((state: any) => state?.category?.detail || {}, shallowEqual)

  useEffect(() => {
    dispatch(getCategory({ page: 1, limit: 1000 }))
    dispatch(getDetailCategory({ id }))
  }, [])

  useEffect(() => {
    if (detail) {
      form.setFieldsValue({ ...detail })
    }
  }, [detail])

  const [form] = Form.useForm()

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
  }

  const onFinish = (value: any) => {
    dispatch(createCategory(value))
  }

  return (
    <PageContainer
      header={{
        title: "Edit Category",
        ghost: true,
      }}
      footer={[
        <Button form="edit-category" htmlType="submit" type="primary">
          Edit category
        </Button>,
      ]}
    >
      <Form id="edit-category" {...layout} form={form} onFinish={onFinish}>
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="slug" label="Slug">
          <Input />
        </Form.Item>
        <Form.Item name="parent_category" label="Parent Category">
          <Select placeholder="Select a option and change input text above" allowClear>
            {categories.map((i: any) => (
              <Select.Option value={i?.key}>{i?.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </PageContainer>
  )
}
