export const GET_TAG = "tag.get"
export const GET_TAG_SUCCESS = "tag.get_success"
export const GET_TAG_FAILED = "tag.get_failed"

export const GET_DETAIL_TAG = "tag.get_detail"
export const GET_DETAIL_TAG_SUCCESS = "tag.get_detail_success"
export const GET_DETAIL_TAG_FAILED = "tag.get_detail_failed"

export const CREATE_TAG = "tag.create"
export const CREATE_TAG_SUCCESS = "tag.create_success"
export const CREATE_TAG_FAILED = "tag.create_failed"
