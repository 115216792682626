import * as ActionTypes from "../actionTypes"
import { ITagState } from "../types"

interface ActionProps {
  type: string
  payload: any
}

const initState: ITagState = {
  list: [],
  detail: {},
}

export const tag = (state: ITagState = initState, action: ActionProps) => {
  const { type, payload } = action

  switch (type) {
    case ActionTypes.GET_TAG_SUCCESS: {
      return {
        ...state,
        list: payload,
      }
    }

    case ActionTypes.CREATE_TAG_SUCCESS: {
      return {
        ...state,
      }
    }

    case ActionTypes.GET_DETAIL_TAG_SUCCESS: {
      return {
        ...state,
        detail: payload,
      }
    }

    default: {
      return state
    }
  }
}
