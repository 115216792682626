import { PageContainer, ProCard } from "@ant-design/pro-components"
import { Button, Result } from "antd"
import { useNavigate } from "react-router-dom"

export default function Unauthorized() {
  const navigate = useNavigate()
  return (
    <PageContainer>
      <ProCard
        style={{
          height: "120vh",
          minHeight: 600,
        }}
      >
        <Result
          status="403"
          style={{ height: "100%" }}
          title="Unauthorized"
          subTitle="Sorry, you are not authorized to access this page."
          extra={
            <Button type="primary" onClick={() => navigate(-1)}>
              Back Home
            </Button>
          }
        />
      </ProCard>
    </PageContainer>
  )
}
