import { useAuthContext } from "@/components/auth"
import { IS_AUTHENTICATED, Paths } from "@/constants"
import { KeyOutlined, UserOutlined } from "@ant-design/icons"
import { Button, Col, Form, Input, Layout, Row } from "antd"
import React from "react"
import { Controller, useForm } from "react-hook-form"
import { Link, Navigate } from "react-router-dom"
import { validation } from "./validation"

const { Content } = Layout

interface LoginDto {
  username: string
  password: string
}
export interface LoginFormData {
  username: string
  password: string
}

export const Login: React.FC = () => {
  const auth = useAuthContext()

  const onLogin = (data: LoginDto) => {
    auth.login(data)
  }

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LoginDto>({
    defaultValues: {
      username: "",
      password: "",
    },
    resolver: validation.authentication.login(),
  })

  const onKeyDown = (e: any) => e.key === "Enter" && handleSubmit(onLogin)

  // @important no change
  // if (auth.isAuthenticated && localStorage.getItem(IS_AUTHENTICATED)) {
  if (localStorage.getItem(IS_AUTHENTICATED)) {
    return <Navigate to={"/"} />
  }

  return (
    <Layout>
      <Content>
        <Row style={{ minHeight: "100vh", alignItems: "center" }}>
          <Col span={6} offset={9}>
            <div style={{ textAlign: "center", marginBottom: "20px" }}>
              {/* <img src="/images/tamda-logo.png" alt="Logo" /> */}
              <h1>TAMDA CMS</h1>
            </div>
            <form onSubmit={handleSubmit(onLogin)} className="login-form">
              <Form.Item>
                <Controller
                  name="username"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => {
                    const { ref, ...props } = field
                    return (
                      <Input
                        {...props}
                        status={errors["username"] ? "error" : undefined}
                        size="large"
                        type="text"
                        placeholder="Tên đăng nhập"
                        prefix={<UserOutlined />}
                        onKeyDown={onKeyDown}
                      />
                    )
                  }}
                />
              </Form.Item>
              <Form.Item>
                <Controller
                  name="password"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => {
                    const { ref, ...props } = field
                    return (
                      <Input.Password
                        {...props}
                        size="large"
                        status={errors["password"] ? "error" : undefined}
                        placeholder="Mật khẩu"
                        prefix={<KeyOutlined />}
                        onKeyDown={onKeyDown}
                      />
                    )
                  }}
                />
              </Form.Item>

              <Form.Item>
                <Row justify="space-between" align="middle">
                  <Button type="primary" htmlType="submit" className="login-form-button">
                    Đăng nhập
                  </Button>
                  <Link to={Paths.PUBLIC.FORGOT_PASSWORD.PATH} className="login-form-forgot">
                    Quên Mật Khẩu
                  </Link>
                </Row>
              </Form.Item>
            </form>
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}
