// import { LoginDto } from "@/__generated__/api-v1"
import { sleep } from "@/utils/sleep"
import { useLocalStorageState } from "ahooks"
import { isEmpty } from "lodash"
import React, { createContext, useContext, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { IS_AUTHENTICATED, USER_INFO } from "../../constants"

import { handleCheckAuthentication, handleLogin, handleLogout } from "@/modules/core/pages/authentication/actions"
import { UserInfo } from "../../types"

interface LoginDto {
  username: string
  password: string
}
export interface AuthContext {
  login: (data: LoginDto) => void
  logout: () => void
  userInfo?: UserInfo | null
  isAuthenticated: boolean | null
}

const authContext = createContext<AuthContext>({} as AuthContext)

const useAuth = (): AuthContext => {
  const [isAuthenticated, setIsAuthenticated] = useLocalStorageState<string | null>(IS_AUTHENTICATED)
  // const [accessToken, setAccessToken] = useLocalStorageState<string | null>(
  //   ACCESS_TOKEN
  // );

  const [userInfo, setUserInfo] = useLocalStorageState<UserInfo | null>(USER_INFO)

  const dispatch = useDispatch()

  const profile = useSelector((state: any) => state.profile)
  const session = useSelector((state: any) => state.auth.session)

  const login = (data: LoginDto) => {
    dispatch(handleLogin(data))
  }

  useEffect(() => {
    if (session.isExpire) {
      logout()
    }

    // eslint-disable-next-line
  }, [session.isCheck])

  const logout = () => {
    dispatch(handleLogout())
    // setAccessToken(null);
    setUserInfo(null)
    // setIsAuthenticated(null);
  }

  useEffect(() => {
    const resolve = async () => {
      // setAccessToken(profile?.accessToken);
      setIsAuthenticated("true")
      await sleep(300)
      // eslint-disable-next-line
      location.href = location.href
    }

    if (!isEmpty(profile?.accessToken)) {
      resolve()
    }

    // eslint-disable-next-line
  }, [profile?.accessToken])

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(handleCheckAuthentication())
    }
    // eslint-disable-next-line
  }, [])

  return {
    userInfo,
    login,
    logout,
    isAuthenticated: !!isAuthenticated,
  }
}

export const AuthProvider: React.FC = ({ children }) => {
  const auth = useAuth()

  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export const useAuthContext = () => useContext(authContext)
