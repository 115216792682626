import history from "@/navigation"
import { dispatch } from "@/redux"
import { PlusOutlined } from "@ant-design/icons"
import { PageContainer, ProColumns, ProTable } from "@ant-design/pro-components"
import { Button } from "antd"
import moment from "moment"
import React, { useEffect } from "react"
import { shallowEqual, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { getPost } from "./actions"

export const List: React.FC = () => {
  const page = 1

  useEffect(() => {
    dispatch(getPost({ page }))
  }, [page])

  const posts = useSelector((state: any) => state?.post?.list || [], shallowEqual)

  type PostItems = {
    key: number
    name: string
    createdAt: number
  }
  const columns: ProColumns<PostItems>[] = [
    {
      title: "Title",
      dataIndex: "name",
    },
    {
      title: "Author",
      dataIndex: "author",
    },
    { title: "Categories", dataIndex: "categories" },
    { title: "Tags", dataIndex: "tags" },
    { title: "Date", dataIndex: "date", renderText: (text: Date) => moment(text).format("MM/DD/YYYY") },
    {
      title: "Action",
      dataIndex: "",
      width: 80,
      fixed: "right",
      render: (_, record) => [<Link to={`/post/edit/${record.key}`}>Edit</Link>],
    },
  ]

  const onCreate = () => {
    history.push("/post/create")
  }

  return (
    <PageContainer
      header={{
        title: "List Posts",
        ghost: true,
        extra: [],
      }}
    >
      <ProTable<PostItems>
        pagination={{ pageSize: 2 }}
        columns={columns}
        dataSource={posts}
        rowKey="key"
        rowSelection={{}}
        search={{
          labelWidth: "auto",
        }}
        dateFormatter="string"
        toolBarRender={() => [
          <Button key="3" type="primary" onClick={onCreate}>
            <PlusOutlined />
            New
          </Button>,
        ]}
      />
    </PageContainer>
  )
}
