import { dispatch } from "@/redux"
import { PageContainer } from "@ant-design/pro-layout"
import { Button, Form, Input } from "antd"
import React, { useEffect } from "react"
import { shallowEqual, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { createTag, getDetailTag } from "./actions"

export const Edit: React.FC = () => {
  const { id } = useParams()
  const [form] = Form.useForm()

  const detail = useSelector((state: any) => state?.tag?.detail || {}, shallowEqual)
  console.log("detail: ", detail)
  useEffect(() => {
    dispatch(getDetailTag({ id }))
  }, [])
  useEffect(() => {
    if (detail) {
      form.setFieldsValue({ ...detail })
    }
  }, [detail])

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
  }

  const onFinish = (value: any) => {
    dispatch(createTag(value))
  }

  return (
    <PageContainer
      header={{
        title: "Edit Tag",
        ghost: true,
        extra: [],
      }}
      footer={[
        <Button form="edit-category" htmlType="submit" type="primary">
          Add new tag
        </Button>,
      ]}
    >
      <Form id="edit-category" {...layout} form={form} onFinish={onFinish}>
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="slug" label="Slug">
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <Input.TextArea rows={5} />
        </Form.Item>
      </Form>
    </PageContainer>
  )
}
