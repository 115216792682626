import { applyMiddleware, compose, createStore } from "redux"
import { createLogger } from "redux-logger"
import createSagaMiddleware from "redux-saga"
import reducers from "./reducers"
import sagas from "./sagas"

const middleware = []

const sagaMiddleware = createSagaMiddleware()

middleware.push(sagaMiddleware)

if (process.env.NODE_ENV === "development") {
  middleware.push(createLogger());
}

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const enhancers = composeEnhancers(applyMiddleware(...middleware))

const store = createStore(reducers, enhancers)

sagaMiddleware.run(sagas)

export const dispatch = store.dispatch
export const getState = store.getState

export type RootState = ReturnType<typeof reducers>
export default store
