import { CREATE_POST, GET_DETAIL_POST, GET_POST } from "../actionTypes"

export const getPost = (payload: any) => {
  return {
    type: GET_POST,
    payload,
  }
}

export const getDetailPost = (payload: any) => {
  return {
    type: GET_DETAIL_POST,
    payload,
  }
}

export const createPost = (payload: any) => {
  return {
    type: CREATE_POST,
    payload,
  }
}
