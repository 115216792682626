import { ProLayoutCustom } from "@/components/layout/ProLayoutCustom"
import { Layout } from "antd"
import { FC } from "react"
import { Outlet } from "react-router-dom"

export const MainLayout: FC = () => {
  return (
    <Layout className="layout" id="">
      <ProLayoutCustom>{<Outlet />} </ProLayoutCustom>
    </Layout>
  )
}
