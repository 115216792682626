import { Outlet } from "react-router-dom"

export const RequireAuth: React.FC = () => {
  // const auth = useAuthContext()

  // if (!auth.isAuthenticated) {
  //   return <Navigate to={Paths.PUBLIC.LOGIN.PATH} />
  // }

  return <Outlet />
}
