import { auth } from "@/modules/core/pages/authentication/reducers"
import { category } from "@/modules/core/pages/category/reducers"
import { post } from "@/modules/core/pages/post/reducers"
import { tag } from "@/modules/core/pages/tag/reducers"
import { combineReducers } from "redux"

export default combineReducers({
  auth,
  post,
  category,
  tag,
})
