import { Create } from "@/modules/core/pages/tag/Create"
import { Edit } from "@/modules/core/pages/tag/Edit"
import { List } from "@/modules/core/pages/tag/List"
import { Route, Routes } from "react-router-dom"

export const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<List />} />
      <Route path="/create" element={<Create />} />
      <Route path="/edit/:id" element={<Edit />} />
    </Routes>
  )
}
