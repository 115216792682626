import history from "@/navigation"
import { all, put, takeLatest } from "redux-saga/effects"
import * as ActionTypes from "../actionTypes"

interface IPayload {
  type: string
  payload: any
}

const FakeTagData = [
  {
    key: 1,
    name: `Music`,
    slug: "https://cms-1.com",
    description: "this is Description ",
    date: new Date(),
  },
  {
    key: 2,
    name: `Guitar`,
    slug: "https://cms-1.com",
    description: "this is Description ",
    date: new Date(),
  },
  {
    key: 3,
    name: `Expensive`,
    slug: "https://cms-1.com",
    description: "this is Description ",
    date: new Date(),
  },
  {
    key: 4,
    name: "good boy",
    slug: "cr8ftsman",
    description: "this is Description ",
    date: new Date(),
  },
]

function* getTag() {
  try {
    yield put({
      type: ActionTypes.GET_TAG_SUCCESS,
      payload: FakeTagData,
    })
  } catch (error) {
    yield put({ type: ActionTypes.GET_TAG_FAILED, payload: error })
  }
}

function* getDetailTag(action: IPayload) {
  try {
    const detail = FakeTagData.filter(i => i.key === +action.payload.id)
    yield put({
      type: ActionTypes.GET_DETAIL_TAG_SUCCESS,
      payload: detail[0],
    })
  } catch (error) {
    yield put({ type: ActionTypes.GET_TAG_FAILED, payload: error })
  }
}

function* createTag(action: IPayload) {
  try {
    yield put({ type: ActionTypes.CREATE_TAG_SUCCESS, payload: action.payload })
    history.push("/tag")
  } catch (error) {
    yield put({ type: ActionTypes.CREATE_TAG_FAILED, payload: error })
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.GET_TAG, getTag),
    takeLatest(ActionTypes.CREATE_TAG, createTag),
    takeLatest(ActionTypes.GET_DETAIL_TAG, getDetailTag),
  ])
}
