export const ACCESS_TOKEN = "access-token"
export const IS_AUTHENTICATED = "is-authenticated"
export const USER_INFO = "user-info"
export const PAGINATION_DEFAULT = { limit: 20, offset: 0 }
export const GETLIST_DEFAULT = { isArchived: false }
export const FORMAT_MOMENT = {
  DDMMYYY: "DD-MM-YYYY HH:MM:SS",
}

export const ACTION_METHOD_OBJ = {
  CREATE: "Tạo",
  UPDATE: "Cập nhật",
  READ: "Chi tiết",
  DELETE: "Xóa",
  ARCHIVE: "Lưu trữ",
  UNARCHIVE: "Hủy lưu trữ",
  ERROR: "Có lỗi xảy ra, vui lòng thử lại!",
}

export const PHONE_REGEX = /((^(\+84|84|0|0084){1})(3|5|7|8|9))+([0-9]{8})$/
