import Unauthorized from "@/components/layout/Unauthorized"
import { Router as CategoryRouter } from "@/modules/core/pages/category/Router"
import { Router as PostRouter } from "@/modules/core/pages/post/Router"
import { Router as TagRouter } from "@/modules/core/pages/tag/Router"
import { Route, Routes } from "react-router-dom"
import { MainLayout } from "./components/layout"
import { Paths } from "./constants"
import { Login } from "./modules/core/pages/authentication/Login"
import history from "./navigation"
import { HistoryRouter } from "./navigation/HistoryRouter"
import { RequireAuth } from "./navigation/RequireAuth"

export const Navigator = () => {
  return (
    <HistoryRouter history={history}>
      <Routes>
        <Route path={Paths.PUBLIC.LOGIN.PATH} element={<Login />} />
        <Route element={<RequireAuth />}>
          <Route element={<MainLayout />}>
            <Route path={`${Paths.PRIVATE.DASHBOARD.PATH}`} element={<h1>Welcome To Tamda CMS</h1>} />
            <Route path={`${Paths.PRIVATE.POST.PATH}/*`} element={<PostRouter />} />
            <Route path={`${Paths.PRIVATE.CATEGORY.PATH}/*`} element={<CategoryRouter />} />
            <Route path={`${Paths.PRIVATE.TAG.PATH}/*`} element={<TagRouter />} />
          </Route>
        </Route>
        <Route path="*" element={<Unauthorized />}></Route>
      </Routes>
    </HistoryRouter>
  )
}
