import history from "@/navigation"
import { all, put, takeLatest } from "redux-saga/effects"
import * as ActionTypes from "../actionTypes"

interface IPayload {
  type: string
  payload: any
}

const FakePostData = [
  {
    key: 1,
    name: `How it all began1`,
    author: "cr8ftsman",
    categories: "Soccer",
    tags: "Ronaldo",
    date: new Date(),
    content: `<h1>hello</h1>`,
  },
  {
    key: 2,
    name: `How it all began2`,
    author: "cr8ftsman",
    categories: "Soccer",
    tags: "Ronaldo",
    date: new Date(),
  },
  {
    key: 3,
    name: `How it all began3`,
    author: "cr8ftsman",
    categories: "Soccer",
    tags: "Ronaldo",
    date: new Date(),
  },
  {
    key: 4,
    name: `How it all began4`,
    author: "cr8ftsman",
    categories: "Soccer",
    tags: "Ronaldo",
    date: new Date(),
  },
]

function* getPost() {
  try {
    yield put({
      type: ActionTypes.GET_POST_SUCCESS,
      payload: FakePostData,
    })
  } catch (error) {
    yield put({ type: ActionTypes.GET_POST_FAILED, payload: error })
  }
}

function* getDetailPost(action: IPayload) {
  try {
    const detail = FakePostData.filter(i => i.key === +action.payload.id)
    yield put({
      type: ActionTypes.GET_DETAIL_POST_SUCCESS,
      payload: detail[0],
    })
  } catch (error) {
    yield put({ type: ActionTypes.GET_DETAIL_POST_FAILED, payload: error })
  }
}

function* createPost(action: IPayload) {
  try {
    yield put({ type: ActionTypes.CREATE_POST_SUCCESS, payload: action.payload })
    history.push("/post")
  } catch (error) {
    yield put({ type: ActionTypes.CREATE_POST_FAILED, payload: error })
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.GET_POST, getPost),
    takeLatest(ActionTypes.CREATE_POST, createPost),
    takeLatest(ActionTypes.GET_DETAIL_POST, getDetailPost),
  ])
}
