export const GET_CATEGORY = "category.get"
export const GET_CATEGORY_SUCCESS = "category.get_success"
export const GET_CATEGORY_FAILED = "category.get_failed"

export const GET_DETAIL_CATEGORY = "category.get_detail"
export const GET_DETAIL_CATEGORY_SUCCESS = "category.get_detail_success"
export const GET_DETAIL_CATEGORY_FAILED = "category.get_detail_failed"

export const CREATE_CATEGORY = "category.create"
export const CREATE_CATEGORY_SUCCESS = "category.create_success"
export const CREATE_CATEGORY_FAILED = "category.create_failed"
