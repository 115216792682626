import { CREATE_TAG, GET_DETAIL_TAG, GET_TAG } from "../actionTypes"

export const getTag = (payload: any) => {
  return {
    type: GET_TAG,
    payload,
  }
}

export const getDetailTag = (payload: any) => {
  return {
    type: GET_DETAIL_TAG,
    payload,
  }
}

export const createTag = (payload: any) => {
  return {
    type: CREATE_TAG,
    payload,
  }
}
