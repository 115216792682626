import { dispatch } from "@/redux"
import { PageContainer } from "@ant-design/pro-layout"
import { Button, Form, Input } from "antd"
import React from "react"
import { createTag } from "./actions"

export const Create: React.FC = () => {
  const [form] = Form.useForm()

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
  }

  const onFinish = (value: any) => {
    console.log("value: ", value)
    dispatch(createTag(value))
  }

  return (
    <PageContainer
      header={{
        title: "Create Category",
        ghost: true,
        extra: [],
      }}
      footer={[
        <Button form="create-category" htmlType="submit" type="primary">
          Add new tag
        </Button>,
      ]}
    >
      <Form
        initialValues={{ name: "", slug: "", parent_category: "" }}
        id="create-category"
        {...layout}
        form={form}
        onFinish={onFinish}
      >
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="slug" label="Slug">
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <Input.TextArea rows={5} />
        </Form.Item>
      </Form>
    </PageContainer>
  )
}
