import { PathItem } from "@/types"

export const DASHBOARD = "/"

export const PUBLIC: PathItem = {
  LOGIN: { PATH: "/login", LABEL: "Đăng nhập", BREADCRUMB: ["Đăng nhập"] },
  FORGOT_PASSWORD: { PATH: "/forgot-password", LABEL: "", BREADCRUMB: [] },
  RESET_PASSWORD: { PATH: "/reset-password", LABEL: "", BREADCRUMB: [] },
}

export const PRIVATE: PathItem = {
  DASHBOARD: { PATH: "/", LABEL: "Dashboard", BREADCRUMB: [] },
  POST: { PATH: "/post", LABEL: "Posts", BREADCRUMB: [] },
  CATEGORY: { PATH: "/category", LABEL: "Category", BREADCRUMB: [] },
  TAG: { PATH: "/tag", LABEL: "Tag", BREADCRUMB: [] },
}
